import React from "react"
import { Row, Container, Col } from 'react-bootstrap';
import Layout from "../components/layout";

import "aos/dist/aos.css";

import NewsBreadcrumbs from "@components/news-intro/news-breadcrumbs";
import NewsIntro from "@components/news-intro/news-intro";
import NewsWave from '@components/news-wave/news-wave';
import NewsContent from '@components/news-content/news-content';
import NewsOtherPost from "@components/news-other-post/news-other-post";
import Sticky from 'react-stickynode';

const NewsDetail = (props) => (
  <div className="news-detail">
    <Layout>
    <NewsBreadcrumbs DetailURL = {props.detailpage} />
      <Container>       
        <Row>
          <Col md={12}>
              <NewsIntro DetailURL = {props.detailpage} />
          </Col>
        </Row>
        <div className="news-detail-content">
          <Row>
            <Col md={12} xl={8} lg={8}>      
                <NewsContent DetailURL = {props.detailpage}/>
            </Col>
            <Col xl={4} lg={4} className="right-side-bar">
              <Sticky top={200} bottomBoundary='.bottomBoundary'>
                  <NewsWave DetailURL = {props.detailpage}/>
              </Sticky>
            </Col>
          </Row>
        </div>
        <div className="bottomBoundary"></div>       
      </Container>
      {<NewsOtherPost DetailURL = {props.detailpage} />}
    </Layout>
  </div>
)

export default NewsDetail
