import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import $ from 'jquery';
import parse from "html-react-parser"
/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */

import TextColumns from '@components/text-columns/text-columns';
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'


const PropertyTextColumns = (props) => {
    const regex = /.*?(\.)(?=\s[A-Z])/;
    const [readMore, setReadMore] = useState(props.description.length >= 700)

    //var propdesc = props.description.replaceAll("&#151;", "-");
    //var descresult = propdesc.replaceAll('&#146;','').replaceAll('&#147;','').replaceAll('&#148;','').replaceAll('&#149;','').replaceAll('&#150;','');

    useEffect(() => {       
        setTimeout(function () {
            //var leftcnt = $(".propertycontent").find('p:first').text()
            //$('.leftprop h3').html(propdesc.html().split("."))
            //var textParts = leftcnt.html().split(".");
            //$(".propertycontent p").first().addClass('first-para')
            //$(".propertycontent .first-para").show()
            var elems = $('.propertycontent .read-more');
            var leftelems = $('.leftprop h3');
            var IntroText = "";
            //console.log('leftelems',leftelems.length)
            if(elems.length == 1) {
                //if(leftelems.length <= 0) {
                   // elems.each( function(){
                        var elem = elems;
                        var textParts = elem.html().replace(/<br>/g,"").split(".");
                        if(typeof(textParts[0])!=="undefined" && textParts[0]) {
                           IntroText = textParts[0].replace(/<span.*?>.*?<\/span>/g, '') +'.'                    
                        }
                        var first = "<p class='firstline text-20-24'>" + textParts.shift() + ".</p>";
                        //console.log(first);
                       // $('.propertycontent .read-less').html(first + textParts.join("."));
                        var htmlText = first + textParts.join(".<br>");
                        
                        $('.propertycontent .read-more').html(htmlText);
                        $('.propertycontent .read-less').html(htmlText.substring(0, 700));
                        
                        //elem.html(htmlText);
                   // });
                    //var leftcnt = $(".propertycontent").find('.firstline').text()
                    if(IntroText) {
                         //$('.leftprop h3').html(IntroText)
                    }else{
                         //$('.leftprop h3').html(leftcnt)
                    }
                //}
            //$('.propertycontent .firstline').hide();
        } else {
            var leftcnt = $(".propertycontent").find('p:first').text()
            $('.leftprop h3').html(leftcnt)
            $(".propertycontent p").first().addClass('first-para')
            //$(".propertycontent .first-para").hide()
        }

        }, 1000);
    }, [])

    return (
        <div className="property-text-columns property-sale" id="details">          
                {/*<div className="leftprop">
                    <h3 className='text-20-24'></h3>
                    {<ul>
                        {
                            props.acc_summ && props.acc_summ.split(',').map((item, index) => {
                                if (item) {
                                    return (
                                        <li>{item}</li>
                                    )
                                }

                            })
                        }
                    </ul>}

                    </div>*/}
                <div className="content propertycontent">  
                <div className={`expand ${readMore ? "panel-closed" : ""}`}>
                { //readMore ?
                <>
                     <div className={`read-less text-20`}>{parse(props.description)}</div>
                     <div className={`read-more text-20`}>{parse(props.description)}</div>
                </>
                //
            }
                </div>
                {props.description.length >= 700 &&
                                <a href="javascript:void(0)" className="readmore_expand text-20" onClick={()=>{setReadMore(!readMore)}}>{readMore ? "Read full description" : "Read Less"}</a>
                }

                    {/*<ReactMarkdown rehypePlugins={[rehypeRaw]} children={descresult}/>*/}
                </div>
           
        </div>
    )

}

export default PropertyTextColumns
