import React, { useState, useEffect } from 'react'
import $ from 'jquery'
import { useInView } from 'react-intersection-observer';
import { useLocation } from "@reach/router"
/**
 * Assets
 */
import './styles/_index.scss';
import { Heart, Share } from '@components/icon/icon';
import SendFriendForm from '@components/forms/sendfrnd-form';
import { SaveItem, useUserObjectState, useAuthState } from "@starberry/myaccount-website-utils";

import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton
}
    from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share"
/**
 * Components
 */

import { ArrowLeft, Bedroom, Bathroom, Reception } from '@components/icon/icon';
import GetOffice from './get-office';


const SocialShare = (props) => {

    const { state, services } = useUserObjectState()
    const { services: authServices } = useAuthState()
    const isAuthenticated = authServices.isAuthenticated()

    const location = useLocation();
    const shareUrl = location.href
    const [ref, inView] = useInView({
        threshold: 0
    });
    const [Shareicons, setShareicons] = React.useState(false);

    const [show, setShow] = useState(false);  
  
    useEffect(() => {
      if (isAuthenticated) {
      // on page load get the users saved objects
          services.getUserobject({ type: "property", state:true })
      }
    }, [])
  

    const handleClose = () => setShow(false);
    const handleShow = (event) => {   
        setShow(true);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Share - social icons',
          'formType': event + ' - ' +shareUrl,
          'formId': 'Share',
          'formName': 'Share',
          'formLabel': 'Share'
        });   
    }

    const openShareicons = () => {
        setShareicons(!Shareicons)
    }
    const trackerShare = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Share - social icons',
          'formType': event + ' - ' +shareUrl,
          'formId': 'Share',
          'formName': 'Share',
          'formLabel': 'Share'
        });    
    }

      return (
            <div className='social-share'>
                <div className="fixed-utils">
                        <SaveItem type="property" pid={props.id}>
                        <i className="heart text-16-14">
                        <Heart />Save
                        </i>
                        </SaveItem><span className='sep'></span>
                        <a href="javascript:;" className="white-btn text-16-14" onClick={openShareicons}>
                                <Share />Share
                                {Shareicons &&
                                <div className="hover-share animate__animated animate__fadeIn">
                                <FacebookShareButton onClick={()=>trackerShare('FacebookShareButton')} url={shareUrl} className="my-share-button facebook-share">
                                <FacebookIcon size={32} round={false} borderRadius={`10`} />
                                </FacebookShareButton>
                                <TwitterShareButton onClick={()=>trackerShare('TwitterShareButton')} url={shareUrl} className="my-share-button twitter-share">
                                <TwitterIcon size={32} round={false} borderRadius={`10`} />
                                </TwitterShareButton>
                                <EmailShareButton  onClick={()=>handleShow('SendToFriend')} className="my-share-button email-share">
                                <EmailIcon size={32} round={false} borderRadius={`10`} />
                                </EmailShareButton>
                                <LinkedinShareButton onClick={()=>trackerShare('LinkedinShareButton')} url={shareUrl} className="my-share-button linked-share">
                                <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                                </LinkedinShareButton>

                                <WhatsappShareButton onClick={()=>trackerShare('WhatsappShareButton')} url={shareUrl} className="my-share-button whatsapp-share">
                                <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                                </WhatsappShareButton>
                                </div>
                                }
                        </a>                                               
                </div>
            </div>  
    )
}

export default SocialShare
