import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { Col, Container, Row, Carousel } from 'react-bootstrap';
import Slider from 'react-slick';
import ImageRenderer from "../ImageRenderer/ImageRenderer";
import { getPropertyUrl } from "../common-data/common-data";
import { Link } from 'gatsby';

/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */

import PropertyBox from '@components/property-box/property-box';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";

const PropertiesBoxes = (props) => {
    console.log("PropertiesBoxes", props)
    const data = useStaticQuery(graphql`
        query {
        pr1: file(relativePath: { eq: "pr1.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        pr2: file(relativePath: { eq: "pr2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `);

    const settings = {
        dots: true,
        arrows: false,
        infinite: props.data.length > 3,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        centerMode: false,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              arrows: false,
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: props.data.length > 3,
              dots: true,
            },
          },
          {
            breakpoint: 992,
            settings: {
              arrows: false,
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: props.data.length > 2,
              dots: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              arrows: false,
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: props.data.length > 1,
              dots: true,
            },
          },
        ],
      };

    let AOS;
    useEffect(() => {
        const AOS = require("aos");
        AOS.init({
            once: true,
            disable: 'mobile'
        });
    }, [])

    useEffect(() => {
        if (AOS) {
            AOS.refresh();
        }
    });

    const Slider = () => (
        <Carousel indicators={true}>
            {props.data && props.data.length > 0 ? props.data.map((item, key) => {
                        let processedImages = JSON.stringify({});
                        if (props.data?.imagetransforms?.images_Transforms) {
                            processedImages = props.data.imagetransforms.images_Transforms;
                        }

                        var checkptype = item.search_type ==="sales"?'/property-for-sale/' :'/property-to-rent/'
                        return <Carousel.Item>
                          
        <div className="property-box">
            <a href={`${checkptype}${item.slug}-${item.id}/`}>
            <div className="property-box__image">
                 <ImageRenderer ImageSrc={item?.images[0]} altText={item?.title} ggfx_results={item?.ggfx_results} strapi_id={item?.id} imagename="property.images.similar" />
            </div>
            </a>
            <div className="property-box__content">
                <a href={`${checkptype}${item.slug}-${item.id}/`}><h4>{item.display_address}</h4></a>
                <div className="price">
                    <span className="d-md-none">Guide Price</span> {'£' + item.price.toLocaleString()}
                </div>
                <small className="beds">
                {item.title}
                </small>
            </div>
        </div>
                        </Carousel.Item>
                    }) : ''}
                    {/* <Col lg="6">
                        <PropertyBox image={data.pr2.childImageSharp.fluid} title="Belmont Close, Totteridge, London, N20" price="£1,200,000" beds="4 bedroom detached house for sale" />
                    </Col> */}        
        </Carousel>
    )
    return (
        <div className={`properties-boxes ${props?.classNames?props?.classNames:''}`} data-aos="fade-up">
            <Container>
                <h4 className='slider-title'>{props.Title}</h4>
                {props.showsale == "true" && props.showrent == "true" && props.data &&
                    <>
                        <div className="property-actions">
                            <a href="/">Property for Sale in Harryingay</a>
                            <a href="/">Property to Rent in Harryingay</a>
                        </div>
                    </>
                }

                <Row className="d-none d-lg-flex d-md-block">
                    {props.data && props.data.length > 0 ? props.data.map((item, key) => {
                         let processedImages = JSON.stringify({});
                         if (props.data?.imagetransforms?.images_Transforms) {
                             processedImages = props.data.imagetransforms.images_Transforms;
                         }
 
                        var checkptype = item.search_type ==="sales"?'/property-for-sale/' :'/property-to-rent/'
                        return <Col md="6" lg="4">
                    <div className="property-box">
                            <a href={`${checkptype}${item.slug}-${item.id}/`}>
                            <div className="property-box__image">
                                 <ImageRenderer ImageSrc={item?.images[0]} altText={item?.title} ggfx_results={item?.ggfx_results} strapi_id={item?.id} imagename="property.images.similar" />
                            </div>
                            </a>
                            <div className="property-box__content">
                                <a href={`${checkptype}${item.slug}-${item.id}/`}><h4 className='text-24 bold'>{item.display_address}</h4></a>
                                <div className="price text-20 bold">
                                    <span className="d-md-none">Guide Price</span> {'£' + item.price.toLocaleString()}
                                </div>
                                <small className="beds text-18">
                                {item.title}
                                </small>
                            </div>
                        </div>
                        </Col>
                    }) : ''}
                    {/* <Col lg="6">
                        <PropertyBox image={data.pr2.childImageSharp.fluid} title="Belmont Close, Totteridge, London, N20" price="£1,200,000" beds="4 bedroom detached house for sale" />
                    </Col> */}
                </Row>
                <div className="d-block d-lg-none d-md-none">
                    <Slider {...settings}/>
                </div>
            </Container>
        </div>
    )
}

export default PropertiesBoxes
