import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './styles/_index.scss';
import MortgageCalc from "../calculators/mortgagecalc/index";
import StampDutyCalc from "../calculators/stampdutycalc/index";
import PropertyContact from "@components/property-contact/property-contact";

const PropertyCalculatorSection = (property) => {
  return (
    <div className='property-calculator-section'>
        <div className='property-calculator-container'>        
            <div className='stamp_cal'>
                <h3 className='text-20-24 bold'>Mortgage Calculator</h3>
                <MortgageCalc imageShow={false} propertyDetails={true} property={property}/>
            </div>
            <PropertyContact negotiator={property?.property?.crm_negotiator_id} propertyurl={property.propertyUrl} officecrmid={property.office_crm_id} layout={'property-details'} />
        </div>
        <div className='property-calculator-container'>
            <div className='mort_cal'>
                <h3 className='text-20-24 bold'>Stamp Duty Calculator
                    <p className='subtext'>Calculate how much stamp duty you will have to pay based on the purchase price of the property.</p>
                    </h3>
                
                <StampDutyCalc imageShow={false} propertyDetails={true} property={property}/>
            </div>
        </div>
    </div>
  )
}

export default PropertyCalculatorSection