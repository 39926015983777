import React, {useEffect} from 'react';
import { Link } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';
import $ from 'jquery'
import './styles/_index.scss';
import { BackArrow } from '@components/icon/icon';
import SocialShare from "@components/property-top/social-share";

const PropertyBack = (props) => { 
     const handleBack = () => {
        $("html, body").scrollTop(0);
    }
    var price= Math.round(props.price)
    var BackURL = ''
    if(props.searchtype === "sales") {
        BackURL = '/property/for-sale/in-london'
    } else {
        BackURL = '/property/to-rent/in-london'
    }
    return (
        <div className="property-back">
            <Container>
                <Row>
                    <Col lg="12">
                        <Link to={`${BackURL}/`} className="left-arrow text-16-14" onClick={handleBack} onKeyPress={handleBack}><BackArrow /><span>Back <span>to search results</span></span></Link>
                    </Col>
                    <Col lg="12">                       
                        <SocialShare/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PropertyBack
