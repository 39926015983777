import React, { useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import { Link } from "gatsby";
import useDeviceDetect from '@blocks/detect-device/detect-device';
import PlayIcon from '@components/play-icon/play-icon';
import {
  isIOS,
  isMobileOnly,
  isBrowser,
  isMobile
} from "react-device-detect";
import ReactPlayer from 'react-player';

const SINGLE_AREA = gql`
query GetTeamDetails ($Prop_Postcode: String!){
    areaGuides (where:{Areas_Coverd_contains:$Prop_Postcode}) {
      URL   
      Title
      Embed_Video_URL   
      Tile_Image {
        url
      }
    }
  
}
`;



function TeamDetails(props) {
  const { isMobile } = useDeviceDetect();
  const [show, setShow] = useState(false);
  const [controls, setVideoControls] = useState(false);
  const [play, setPlayvideo] = useState(true);
  const [mute, setMute] = useState(true);
  const [showHTMLVideo, setHTMLVideo] = React.useState(false);
  const vidRef = useRef(null);
  const [showVideo, setShowVideo] = React.useState(false);
  const [isPlay,setPlay] = React.useState(false);
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const playvideo = (ref) => {
    setPlayvideo(true)
    setVideoControls(false)
}
const showControls = () => {
    setVideoControls(true)
}
  const trackerVideo = (event) => {    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Video Play Btn',
      'formType': url,
      'formId': 'Video Play',
      'formName': 'Video',
      'formLabel': 'Video'
    });
  }  

  const { loading, error, data } = useQuery(SINGLE_AREA, {
    variables: { Prop_Postcode: props.area }
  });
  if (loading) return <p>Loading ...</p>;
  return (
    <>
      {data && data.areaGuides && data.areaGuides.map((node, key) => {
        if(key ===0) {
        return <>
        {/*<Video src={node.Embed_Video_URL} height={isMobile ? '250px' : '400px'} image={node.Tile_Image.url} />*/}
        <div className='guide-video'>
            <div className="vide-wrapper video-box">
                <img src={node.Tile_Image.url} className='react-player__preview'/>
                <div className="sep-play">
                  <a onClick={() => { setHTMLVideo(true); playvideo(); setShow(true); }}>
                  <PlayIcon />
                  </a>
                </div>           
                <>             
                  {showHTMLVideo &&
                  <Modal
                  show={show}
                  onHide={() => { setHTMLVideo(false); playvideo(); setShow(false); }}
                  dialogClassName="modal-video"
                  aria-labelledby="example-custom-modal-styling-title"
                  backdropClassName="video-backdrop"
                  >
                  <Modal.Header closeButton>
                  </Modal.Header>
                  <Modal.Body>
                  <ReactPlayer autoplay ref={vidRef} onPlay={trackerVideo(node.Title)} url={node.Embed_Video_URL}  controls={true} autoplay={true} frameborder="0" muted={false} playsinline={true} playing={play} onEnded={() => { setShowVideo(false); }} width='100%' height='100%' />
                  </Modal.Body>
                  </Modal>
                  }
                </>          
            </div>
        </div>
        <div className='area-guide-text'>
          <h5 className='text-24 bold'>Local Area Guide</h5>
          <ul>
          <li><span><Link to={`/about/area-guide/${node && node.URL}/`} onClick={() => { setHTMLVideo(true); playvideo(); setShow(true); }}>Read {node.Title} Area Guide</Link></span></li>
          </ul>
        </div>
        </>
        }
      })}
    </>
  )
}

export default TeamDetails
