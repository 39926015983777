import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import ModuleRenderer from "../components/ModuleRenderer/ModuleRenderer";

const CommonPageTemplate = (props) => {
    const Page = props?.Page;
    const PageModules = props?.Modules;
    const ReviewsData = props?.GlobalConfig?.Elfsight_Reviews;
    const FormData = props?.GlobalConfig?.GetStartedForm;
    const OfficeList = props?.OfficeList;
    const classAttribute = `default-page-wrap ${Page?.Pagename ? `page-${Page.Pagename.replace(/\s+/g, '-').toLowerCase()}` : ''} ${Page?.Layout || ''} ${Page?.Custom_CSS_Class || ''}`;

    return (
        <Layout classAttribute={classAttribute} popularSearch={Page?.Select_Popular_Search}>
            <SEO title={Page?.Meta_Title} description={Page?.Meta_Description} />
            <Breadcrumbs />  
            <ModuleRenderer Modules={PageModules} StrapiId={Page?.strapiId} ggfx_results={Page?.ggfx_results} PageAlias={Page?.Alias} TeamData={Page?.Team_Members} ReviewsData={ReviewsData} FormData={FormData} OfficeList={OfficeList} TeamSlider={props?.TeamSlider} JobOpenings={props?.JobOpenings} />
        </Layout>
    );
};

export default CommonPageTemplate;