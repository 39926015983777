import React, { useEffect } from "react"
import Img from 'gatsby-image';
import $ from "jquery";
import { Link, withPrefix } from "gatsby";
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw';

import LandingImg from "../../images/valuation-bg.png";
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import ChatForm from '@blocks/forms/chat-form';
import { useMatch } from "@reach/router"
import SEO from "@components/seo"

import Valuationform from '@components/forms/valution-form';
import ThankYou from "../thank-you/ThankYou";
import { isMobile } from "../../hooks/useDeviceMedia";

import "aos/dist/aos.css";
import '@blocks/forms/styles/_index.scss';
import './styles/_valuation.scss';

const Contact = (props) => {
  const valuationpage = useMatch("/value-my-property/:item/")
  const thankYouPage = useMatch("/value-my-property/property-valuation/:item/")
  //console.log('aaaaaaaaaaaa',valuationpage)
  const [showResults, setShowResults] = React.useState(false)
  const [showLanding, setshowLanding] = React.useState(true)
  const [showthankyou, setShowthankyou] = React.useState(false)
  useEffect(() => {
    if (valuationpage && valuationpage !== null) {
      setshowLanding(false)
      setShowResults(true)
    }
    if (thankYouPage && thankYouPage !== null) {
      setshowLanding(false)
      setShowthankyou(true)
    }
  });

  function onClick() {
    setShowResults(true)
    setshowLanding(false)
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

  }
  if (typeof window !== "undefined") {
    $(window).scroll(function () {
      var sticky = $('.sticky-header'),
        scroll = $(window).scrollTop();

      if (scroll >= 40) sticky.addClass('fixed');
      else sticky.removeClass('fixed');
    });
  }


  return (
    <>


      <div className="valuation-content spacing-bottom-only">
        <div className="banner-img">
          <img src={LandingImg} alt="img" />
        </div>
        {showthankyou && isMobile ? "" : <div className="spacing-bottom-32"><Breadcrumbs /></div>}
        <Container>
          {showResults ?
            <Row className="justify-content-md-center animate__animated animate__fadeInUp">
              <SEO title={`Property Valuation`} description={`Book your property valuation now. You can book a free property valuation with us for accurate property valuations in North London & Hertfordshire.`} />
              <h1 className="d-none">Property Valuation</h1>
              <Col md="12" lg={6}>
                <Valuationform />
              </Col>
            </Row>
            : showthankyou ? <ThankYou /> : null}
          {showLanding &&
            <>
              {/* <Breadcrumbs /> */}
              <div className="landing-head text-center spacing-bottom-48">
                <h1 className="spacing-bottom-32">{props.Title}</h1>
                <ReactMarkdown rehypePlugins={[rehypeRaw]} children={props.Content} />
              </div>
              {/* property-wrap start */}
              <Row className="landing-items">
                <Col xl="6">
                  <div className="landing-block text-center">
                    <Img fluid={props.LeftImg.childImageSharp.fluid} alt={`${props.LeftTitle} - Anthony Pepe`} />
                    <div className="landing-block-content">
                          <h3 className="spacing-bottom-32">{props.LeftTitle}</h3>
                          <ReactMarkdown rehypePlugins={[rehypeRaw]} children={props.LeftContent} className="spacing-bottom-24" />
                          <Link to="/value-my-property/property-valuation/" className="btn btn-default openvaluationform" href="javascript:;">{props.LeftCta}</Link>
                    </div>
                  </div>
                </Col>
                <Col xl="6">
                  <div className="landing-block text-center">
                    <Img fluid={props.RightImg.childImageSharp.fluid} alt={`${props.RightTitle} - Anthony Pepe`} />
                    <div className="landing-block-content">
                    <h3 className="spacing-bottom-32">{props.RightTitle}</h3>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} children={props.RightContent} className="spacing-bottom-24" />
                    <a target="_blank" className="btn btn-default" href={props.RightLink}>{props.RightCta}</a>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          }
          {/* property-wrap end */}
        </Container>
      </div>

    </>
  )
}

export default Contact
