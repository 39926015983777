import React from 'react'
import { Container,Tab,Col,Row,Nav } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import PersonVideo from '@components/person-video/person-video';
import { Link } from "gatsby";
import { Logo } from '@components/icon/icon';
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'

/**
 * Assets
 */

function VideosPage(props) {
  
    return (
        <>
        <div className="awards-section static-person-video team-listing-intro">
            <Container>                
                
<Tab.Container id="controlled-tab-example" defaultActiveKey={props.data[0].id}>
  <Row>
    <Col sm={12}>
      <Nav variant="pills" className="flex-column">
        <Nav.Item className="category-list-team">
        {props.data.map((award,key) => {
            return<>
                <li><Nav.Link eventKey={award.id}>{award.Year}</Nav.Link></li>                
            </>
        })}
        </Nav.Item>
      </Nav>
    </Col>
    <Col sm={12} className="text-columns">
      <Tab.Content>
      {props.data.map((award,key) => {
          return<>
        <Tab.Pane eventKey={award.id}>
          <ReactMarkdown rehypePlugins={[rehypeRaw]} children={award.Content}/>            
        </Tab.Pane>
        </>
        })}
      </Tab.Content>
    </Col>
  </Row>
</Tab.Container>
            </Container>
        </div>

        </>
    )

                }
export default VideosPage
