import React,{useEffect, useState} from 'react';
import { Col, Row } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal'

import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import BookaViewingForm from '@components/forms/book-a-view-form';
import './styles/_index.scss';
import GetOffice from '@components/property-top/get-office';
import { Envelop, Home, Phone } from '@components/icon/icon';

const SINGLE_TEAM = gql`
query GetTeamDetails ($EmailID: String!){
    teams (where:{Email_contains:$EmailID}) {
        id
        URL
        Name
        Email
        Phone
        Designation
        Image {
          url
        }
  }
}
`;

const SINGLE_OFFICE = gql`
query GetTeamDetails ($CRM_Office_ID: String!){
  offices (where:{CRM_Office_ID:$CRM_Office_ID}) {
    Title
    URL
    Phone_Number
    CRM_Office_ID
  }
}
`;

const ContactBoxs = (props) => {

  const { loading, error, data } = useQuery(SINGLE_TEAM, {
    variables: { EmailID: (props.negotiator == undefined ?  props.negotiator_details.email :  props.negotiator.email)}
  });

  const { loading: officeLoad, error: officeErr, data: officeList} = useQuery(SINGLE_OFFICE, {
    variables: { CRM_Office_ID: props.officecrmid }
  });

  //console.log("officeList", officeList?.offices?.length, officeList?.offices[0]?.Phone_Number)

  const email = "text"
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [teamid, setteamid] = useState(false);

  const handleShow = (event) => {
    setteamid(event);
    setShow(true);
}

    return (
        <div className="contact-box" data-aos="fade-right"  data-aos-offset="0">          
                    <div className='button-list'>
                      <GetOffice officecrmid={props.officecrmid}/>
                      <a className='text-20 btn  enquire' href="javascript:;" onClick={ () => handleShow(email)}> <Envelop /> Enquire</a>
                      <a className='text-20 btn alt ' href="javascript:;" onClick={ () => handleShow(email)}> <Home /> Sell your home with us</a>
                    </div>
                    <div className='button-list-mobile'>                      
                        <div className='list-item'>
                          {officeList?.offices[0]?.Phone_Number &&
                            <a className={`text-20 btn `} href={`tel:${officeList?.offices[0]?.Phone_Number}`}><Phone className="respnsive" />Call</a>
                          }
                          <a className={`text-20 btn alt  ${officeList?.offices?.length?'':'single-btn'}`} href="javascript:;" onClick={ () => handleShow(email)}> <Envelop /> Enquire</a>
                        </div>                               
                    </div>
                    <div className='button-list-tablet'>
                       <div className='container'>
                          <div className='row'>
                            <div className='col-md-12'>
                            <div className='list-item'>
                                {officeList?.offices[0]?.Phone_Number &&
                                <a className={`text-20 btn  phone`} href={`tel:${officeList?.offices[0]?.Phone_Number}`}><Phone className="respnsive" />{officeList?.offices[0]?.Phone_Number}</a>
                                }
                                <a className={`text-20 btn alt  ${officeList?.offices?.length?'':'dobule-btn'}`} href="javascript:;" onClick={ () => handleShow(email)}> <Envelop /> Enquire</a>
                                <a className={`text-20 btn alt right  ${officeList?.offices?.length?'':'dobule-btn'}`} href="javascript:;" onClick={ () => handleShow(email)}> <Home /> Book a valuation</a>
                            </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <>
                      <Modal
                      show={show}
                      centered={true}
                      onHide={handleClose}
                      backdrop="static"
                      keyboard={false}
                      dialogClassName="modal-90w modal-form book-viewing-form">

                      <Modal.Header closeButton className="contact-close-btn memberPopup">
                      <Modal.Title className="w-100 ">What are you looking for?
                      </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                         <BookaViewingForm propertyurl = {props.propertyurl}/>
                      </Modal.Body>
                      </Modal>
                    </>  
                    
                
        </div>
    )
}

export default ContactBoxs;
