import React, { useState, useEffect } from "react";
import Select from "react-select";
import ElfsightWidget from "./ElfsightWidget";
import "./ElfsightGoogleWidgetModule.scss";

const ElfsightGoogleWidgetModule = ( props ) => {

  const [widgetID, setWidgetID] = useState(props?.WidgetID);
  const OfficeList = props?.OfficeList;

 
  const options = Array.isArray(OfficeList?.edges)
    ? OfficeList.edges
        .map(edge => edge.node)
        .filter(office => office.Elfsight_Widget_Code)
        .map(office => ({
          value: office.Elfsight_Widget_Code,
          label: office.Title,
        }))
    : [];


  useEffect(() => {
    console.log("OfficeList:", OfficeList);
    console.log("Options:", options);
  }, [OfficeList]);


  useEffect(() => {
    console.log("widgetID:", widgetID);
  }, [widgetID]);

  
  const handleSelectChange = selectedOption => {
    console.log("Selected Option:", selectedOption);
    setWidgetID(selectedOption.value);
  };
  
  console.log("widgetIDzz:", widgetID);
  return (
    <div className="elfsight-reviews-wrapper">
      <div className="elfsight-reviews-filter mb-4">
        <Select
          placeholder="Choose your local branch"
          options={options}
          onChange={handleSelectChange}
          isSearchable={false}
          defaultValue={options.filter((wid)=>wid.value == widgetID)}
          // menuIsOpen={true}
        />
      </div>
      <div className="elfsight-google-widget-module">
        {options.map((widget)=>{
          return ( <ElfsightWidget widgetID={widget.value} currentId={widgetID} />)
        })}
        {/* {widgetID && <ElfsightWidget widgetID={widgetID} />} */}
      </div>
    </div>
  );
};

export default ElfsightGoogleWidgetModule;
